<template>
    <v-container fluid>
        <material-card title="Tags">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Calories</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in desserts" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>{{ item.calories }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </material-card>
    </v-container>
</template>

<script>

    export default {
        components: {},
        data() {
            return {
                url: '',
                type: '',
                count: 0,
                data: [],
                callback_function: {},
                items: [],
                selected_index: undefined
            }
        },
        created() {
            this.load()
        },
        methods: {
            load() {
                this.url = 'category-manager/categories/';
                this.type = 'get';
                this.callback_function = this.load_callback;
                this.count++;
            },
            load_callback(r)
            {
                this.items.push(r.data);
            },
            update(item) {
                this.url = 'category-manager/categories/'+item.id+'/';
                this.type = 'patch';
                this.callback_function = this.update_callback;
                this.count++;
            },
            update_callback(r)
            {
                console.log(r);
                console.log('updated');
            },
            delete(item, index) {
                this.url = 'category-manager/categories/'+item.id+'/';
                this.type = 'patch';
                this.callback_function = this.delete_callback;
                this.selected_index = index;
                this.count++;
            },
            delete_callback(r)
            {
                this.items.splice(this.selected_index, 1);
                console.log(r);
                console.log('deleted');
            }
        }
    }
</script>
